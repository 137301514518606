
<body>
    <div class="text">
    <p>I vårt breda däcksortiment hittar du definitivt det du vill ha!</p>
    <p>Vi hjälper dig hitta rätt i däckdjungeln, vi har däck & fälgar i alla prisklasser och för alla tillfällen.</p>
    <p>Hör av dig till oss så hjälper vi dig!</p>
</div>
<ngb-carousel *ngIf="images" [interval]="2000" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">
  <ng-template ngbSlide *ngFor="let image of images">
    <div class="picsum-img-wrapper">
      <img [src]="image" alt="Random slide">
    </div>
    
  </ng-template>
</ngb-carousel>




  </body>