<body>



    
<div class="text">
<p>Välkommen till Österbydäck & Allservice! </p>
<p> Vi har det mesta inom däck oavsett om det gäller bilar, mc, lastbilar eller skottkärror.</p>
<p> Vi servar din bil med modern utrustning och teknik. Med originaldelar från Autoexperten ingår 3 års garanti på reservdelar.</p>


  
  <br>

   
<p> 

  <br>
 
</p>
</div>

<ngb-carousel #carousel [interval]="60000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
  <ng-template ngbSlide *ngFor="let img of images; index as i">
    
    
      <div class="picsum-img-wrapper">
        <img [src]="img" alt="My image {{i + 1}} description">
      </div>
  
  </ng-template>
</ngb-carousel>
<br>
<br>
<br>
<br>
<br>
<br>



</body>