
<app-header></app-header>

<body>

<div class="sidenav">
    <nav>
        <ul>
            <li><a href="#" routerLink="/" >Hem</a></li>
            <li><a href="#" routerLink="/service" >Service</a></li>
            <li><a href="#" routerLink="/tires" >Däck</a></li>
            <li><a href="#" routerLink="/openinghours" >Öppettider</a></li>
            <li><a href="#" routerLink="/aboutus" >Om oss</a></li>
            <li><a href="#" routerLink="/storage" >Däckhotel & Vinterförvaring</a></li>
            <li><a href="#" routerLink="/findus" >Hitta hit</a></li>
            <li><a href="#" routerLink="/contactus" >Kontakta oss</a></li>            
        </ul>
    </nav>
    </div>


    
    <div class="container">
        <router-outlet></router-outlet>
    </div>
</body>

<div class ="footeradjuster"></div>
    <app-footer></app-footer>

