import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-openinghours',
  templateUrl: './openinghours.component.html',
  styleUrls: ['./openinghours.component.css', '../app.component.css']
})
export class OpeninghoursComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
