import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-findus',
  templateUrl: './findus.component.html',
  styleUrls: ['./findus.component.css']
})
export class FindusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
