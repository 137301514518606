<div class = "footer">
<div class = "column">
    <img src="../../assets/images/soliditet.jpg" alt="bild">
</div>
<div class ="column">
    <div class ="text1">
        <p>Vi innehar högsta kreditvärdighet sedan 2015 enligt Bisnode.</p>
    </div>
    </div>
    <div class ="column">
        <div class ="text1">
            
        </div>
        </div>
        <div class ="column">
            <div class ="text1">
               <a href= "https://www.facebook.com/osterbydackochallservice/">
        <img src="../../assets/images/fbooklogo.png" alt="bild">
        </a> 
            </div>
            </div>
            <div class ="column">
                <div class ="text1">
                     <a href= "https://www.instagram.com/firststopsv/">
    <img src="../../assets/images/instagramlogo.png" alt="bild">
    </a>
                </div>
                </div>
<div class ="column">
  
</div>
    <div class ="column">
   
</div>


<div class="column">
    <a href= "https://www.resursbank.se/betala/kreditkort?gclid=CjwKCAjws8yUBhA1EiwAi_tpEbri-dxsOERxfC_E9zNLNlCAdb4MrmF71ifJ6yv3aP8peCR7L2LpjRoCzSMQAvD_BwE">
      <img src="../../assets/images/resursbanken.png">
    </a>
    </div>

    <div class ="column">
        <div class ="text1">
            
        </div>
        </div>
    <div class ="column">
        <div class ="text2">
            
        </div>
    </div>

</div>
 <!---osterbydack@hotmail.com
--->