import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tires-component',
  templateUrl: './tires.component.html',
  styleUrls: ['./tires.component.css', '../app.component.css']
})
export class TiresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  images = ['../../assets/images/bridgestone_tyres_new.png', '../../assets/images/kumho_tyres.png', '../../assets/images/nokian_tyres.jpg', '../../assets/images/michelin_tyres.jpg','../../assets/images/hankook_tyres.png', '../../assets/images/continental_tyres.jpg'];
  showNavigationArrows = false;
  showNavigationIndicators = false;   
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  

  

  
  }
  
