import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ServiceComponent } from './service/service.component';
import { TiresComponent } from './tires/tires.component';
import { OpeninghoursComponent } from './openinghours/openinghours.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { StorageComponent } from './storage/storage.component';
import { FindusComponent } from './findus/findus.component';
import { ContactusComponent } from './contactus/contactus.component';


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'service', component: ServiceComponent},
  { path: 'tires', component: TiresComponent},
  { path: 'openinghours', component: OpeninghoursComponent},
  { path: 'aboutus', component: AboutusComponent},
  { path: 'storage', component: StorageComponent},
  { path: 'findus', component: FindusComponent},
  { path: 'contactus', component: ContactusComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
