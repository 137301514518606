<body>
    <div class="text">
    <p>Vi erbjuder förvaring på vårat däckhotel, här kan du tryggt förvara dina hjul i väntan på nästa säsong.</p>
    <p>Från 800kr/säsong där däckskifte ingår.</p>
    <p>Du kan enkelt boka tid för däckskifte genom First Stops<a href= "https://www.firststop.se/dack-sokresultat?subdomain=firststoposterbydackallserviceab&tyrechange=true&id=1476" > bokningssystem</a></p>
    <br>
    <br>
    <br>
    <br>
    <br>
  
    </div>
    </body>
